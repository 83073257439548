<template>
  <div v-if="showArrowButton" class="main_hidden">
    <a-icon type="caret-left" class="left" @click="handleLeft"></a-icon>
    <div class="image_container_hidden" style="width: 100%" id="imageDiv">
      <div class="image_div" v-for="item in currentList">
        <div style="height: calc(100% - 20px);" :class="{'is-active': current.id === item.id}">
            <img :alt="item.id" :key="item.id" :src="item.url" @click="imageClick(item)"/>
            <div v-if="allowClickImage" class="mask" :ref="item.id" :style="{opacity:0}"
                @click="selectImage(item.id)">
            </div>
          </div>
          <div class="image_name" :title="item.name">{{ item.name }}</div>
      </div>
    </div>
    <a-icon type="caret-right" class="right" @click="handleRight"></a-icon>
  </div>
  <div v-else class="main">
    <div class="image_container" :style="{width:fullWidth+'px'}">
      <div class="image_div" style="display:inline-block;" v-for="item in currentList">
        <img width="98" height="111" :alt="item.id" :key="item.id" :src="item.url" @click="imageClick(item)"/>
        <div v-if="allowClickImage" class="mask" :ref="item.id" :style="{opacity:0}"
             @click="selectImage(item.id)"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageList',
  props: {
    imageFiles: {
      type: Array,
      required: true
    },
    fullWidth: {
      type: Number,
      default: 988
    },
    showArrowButton: {
      type: Boolean,
      default: true
    },
    allowClickImage: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
    },
  },
  data() {
    return {
      startIndex: 0,
      imageListWidth: 880,
      opacityMap: new Map(),
      current: {},
      currentList: [],
      imageShowNumber: 0,

    }
  },
  created() {
    this.imageListWidth = this.fullWidth - 65;
    if(this.size) {
      this.imageShowNumber = this.size;
      return;
    }
    this.$nextTick(() => {
      const width = document.getElementById('imageDiv')?.clientWidth;
      let number = Math.ceil(width / 155);
      this.imageShowNumber = number;
      this.getCurrentList();
    });
  },
  watch: {
    imageFiles() {
      if(this.imageFiles.length > 0) {
        this.getCurrentList();
      }
    }
  },
  computed: {},
  methods: {
    getCurrentList() {
      let currentList = [];
      if(this.showArrowButton){
        let endIndex = this.startIndex + this.imageShowNumber;
        currentList = this.imageFiles.slice(this.startIndex, endIndex);
      }else{
        currentList = this.imageFiles;
      }
      this.currentList = currentList;
    },
    getImageIds() {
      let imageids = [];
      this.opacityMap.forEach((value, key) => {
        if (value) {
          imageids.push(key);
          this.opacityMap.set(key, 0);
          this.$refs[key][0].style.opacity = "0";
        }
      });
      return imageids;
    },
    handleLeft() {
      if (this.startIndex > 0) {
        this.startIndex--;
      }
      this.getCurrentList();
    },
    handleRight() {
      let endIndex = this.startIndex + this.imageShowNumber;
      if (endIndex < this.imageFiles.length) {
        this.startIndex++;
      }
      this.getCurrentList();
    },
    selectImage(id) {
      let oldvalue = this.opacityMap.get(id);
      if (oldvalue) {
        oldvalue = 0;
      } else {
        oldvalue = 1;
      }
      this.opacityMap.set(id, oldvalue);
      this.$refs[id][0].style.opacity = oldvalue + "";
    },
    // 图片点击
    imageClick(item){
      this.current = item;
      this.$emit("imageClick", item);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}

.main_hidden {
  border: 2px inset;
  background: white;
  /*height: 130px;*/
  /*overflow: hidden;*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_container_hidden {
  /*overflow: hidden;*/
  /*height: 117px;*/
  min-height:60px;
  display: flex;
  justify-content: flex-start;
  padding: 2px 0px;
  margin: 2px;
  flex: 1;
  overflow: hidden;
}

.main {
  border: 2px inset;
  background: white;
  /*height: 130px;*/
  /*overflow: hidden;*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_container {
  /*overflow: hidden;*/
  /*height: 117px;*/
  min-height:60px;
  flex-direction: row;
  flex-wrap: wrap;
  /*justify-content: space-around;*/
  align-items: center;
  padding: 2px 0px;
  margin: 2px;
}
img {
  border: 1px solid gray;
  /* margin: 0px 6px; */
}


.left, .right {
  font-size:24px;
  background: white;
  color: lightskyblue;
  border: none;
}

.image_div {
  position: relative;
  margin-right:  10px;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.image_div .is-active {
  border: 1px lightgreen solid;
}

.mask {
  position: absolute;
  width: 96px;
  height: 109px;
  top: 1px;
  left: 8px;
  background: rgb(101, 101, 101, 0.6);
}
.image_name {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
