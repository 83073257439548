<template>
    <a-spin :spinning="loading">
        <div style="" class="opera-home">
            <!-- <div class="menu-nav">
                <div>
                    <a-icon type="setting" class="menu-nav-icon"></a-icon>
                    <span class="menu-nav-text">常规操作</span>
                </div>
                <a-button-group>
                    <a-button style="" type="primary" icon="reload" @click="reload" v-hasPermi="['opera_reset']">重置</a-button>
                    <a-button style="margin-left:10px;" type="primary" icon="export" v-hasPermi="['opera_export']" @click="exportImage" :disabled="!this.currentImageImageInfo.id">导出</a-button>
                </a-button-group>
            </div> -->

            <div class="table-search">
                <a-form-model :model="queryParams" ref="queryForm" size="small" layout="inline" label-width="68px">
<!--                    <a-form-model-item label="开始时间" prop="beginTime">
                        <a-date-picker
                            v-model="queryParams.beginTime"
                            show-time
                            valueFormat="YYYY-MM-DD HH:mm:ss"
                            placeholder="请选择日期"
                            allowClear
                        />
                    </a-form-model-item>
                    <a-form-model-item label="结束时间" prop="endTime">
                        <a-date-picker
                            v-model="queryParams.endTime"
                            show-time
                            valueFormat="YYYY-MM-DD HH:mm:ss"
                            placeholder="请选择日期"
                            allowClear
                        />
                    </a-form-model-item>-->
<!--                    <a-form-model-item label="文件名称" prop="folderName">
                        <a-select v-model="queryParams.folderName" style="width:200px;" placeholder="请选择文件名称" allowClear show-search>
                            <a-select-option v-for="item in folderList" :key="item.folderName" :value="item.folderName">{{ item.folderName }}</a-select-option>
                        </a-select>
                    </a-form-model-item>-->
                  <a-form-model-item label="样品编号" prop="sampleNumber">
                    <a-select v-model="queryParams.sampleNumber" style="width:200px;" placeholder="请选择样品编号" allowClear show-search>
                      <a-select-option v-for="item in sampleNumberList" :key="item.sampleNumber" :value="item.sampleNumber" :title="item.sampleNumber">{{ item.sampleNumber }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                    <a-form-model-item label="模型名称" prop="modelName">
                        <a-select v-model="queryParams.modelName" style="width:200px;" placeholder="请选择模型名称" allowClear show-search @change="changeModelName">
                            <a-select-option v-for="item in modelNameList" :key="item.modelName" :value="item.modelName" :title="item.modelName">{{ item.modelName }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="模型版本" prop="modelVersion">
                        <a-select v-model="queryParams.modelVersion" style="width:200px;" placeholder="请选择模型版本" allowClear show-search @change="optionResultInforList">
                            <a-select-option v-for="item in modelVersionList" :key="item.modelVersion" :value="item.modelVersion" :title="item.modelVersion">{{ item.modelVersion }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="识别信息" prop="expertmentalInformation">
                        <a-select v-model="queryParams.expertmentalInformation" style="width:320px;" placeholder="请选择实验信息" allowClear show-search>
                            <a-select-option v-for="item in resultInfoList" :key="item.expertmentalInformation" :value="item.expertmentalInformation" :title="item.expertmentalInformation">{{ item.expertmentalInformation }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-button icon="search" type="primary" v-hasPermi="['opera_search']"  @click="querySearch">查询</a-button>
<!--                        <a-button icon="a-icon-refresh" @click="reload" style="margin-left: 10px">重置</a-button>-->
                    </a-form-model-item>
                    <a-form-model-item style="float: right;margin-right: 0px;">
                        <a-button style="" type="primary" icon="reload" @click="reload" v-hasPermi="['opera_reset']">重置</a-button>
                        <!-- <a-button style="margin-left:10px;" type="primary" icon="save">保存</a-button> -->
                        <a-button style="margin-left:10px;" type="primary" icon="export" v-hasPermi="['opera_export']" @click="exportImage" :disabled="!this.currentImageImageInfo.id">导出</a-button>
                        </a-form-model-item>
                </a-form-model>
            </div>

            <div v-if="imageFiles.length > 0" class="opera-img-list">
                <image-list ref="eleImageList" :image-files="imageFiles" :allow-click-image="false" @imageClick="imageClick"/>
            </div>

             <!--<div style="display: flex;justify-content: flex-end;margin-bottom: 10px;" v-if="currentImageImageInfo.id">
               <a-button type="primary" style="margin-right: 10px" @click="imageRegAgain">重新识别</a-button>
                <a-button type="primary" style="margin-right: 10px" @click="handleDrawOpen">图像标注</a-button>

                 <a-button type="primary" style="margin-right: 10px" @click="originModalOpen">查看原图</a-button>
                 <a-button type="primary"  @click="imageClick(currentImage)">刷新</a-button>
            </div>-->

            <div class="opera-main" v-if="imageFiles.length > 0">
                <div class="opera-img">
                    <handle-image :imgUrl="currentImage.imageUrls" @imageDraw="handleDrawOpen" @originImage="originModalOpen" @refresh="imageClick(currentImage)" @recognition="imageRegAgain"/>
                </div>
                <div class="opera-info">
                    <div style="text-align: left;font-weight: bold;padding: 5px 0 0 5px;">分析结果</div>
                    <div v-if="currentImageImageInfo.id && columns.length > 0" >
                        <div class="opera-table-tltle">
                            <div class="ott-tab" v-for="item in columns" :key="item.title">{{item.title}}</div>
                        </div>
                        <div clsss="opera-table-detail" v-for="ele in imageInfoList" :key="ele.name">
                            <div class="otd-title">{{ele.name}}</div>
                            <div class="otd-list" v-for="info in ele.list" :key="info.id">
                                <div class="otd-tab" v-for="col in columns" :key="col.title">
                                    <div v-if="col.dataIndex === 'groupId'">
                                        <a-select v-model="info[col.dataIndex]" placeholder="请选择组别" @change="e => changImageInfo(e, info, 'groupId')">
                                            <a-select-option v-for="(item, index) in groupList" :key="index + 1" :value="index + 1">{{ index+ 1 }}</a-select-option>
                                        </a-select>
                                    </div>
                                    <div v-if="col.dataIndex === 'levelNum'">
                                        <a-select v-model="info[col.dataIndex]" placeholder="请选择层数" @change="e => changImageInfo(e, info, 'groupId')">
                                            <a-select-option v-for="(item, index) in levelList" :key="index + 1" :value="index + 1">{{ index+ 1 }}</a-select-option>
                                        </a-select>
                                    </div>
                                    <div v-if="col.dataIndex !== 'groupId' && col.dataIndex !== 'levelNum'">{{info[col.dataIndex]}}</div>
                                    <div class="otd-tab" v-if="col.dataIndex === 'action'">
                                        <a v-if="info.id != null" @click="deleteById(info)">删除</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <a-table rowKey="id" :columns="columns" :dataSource="imageInfoList"
                        ref="operaImageInfo" :pagination="false" bordered>
                    <template v-slot:group="text, record">
                         <a-select v-model="record.groupId" placeholder="请选择组别" @change="e => changImageInfo(e, record, 'groupId')">
                            <a-select-option v-for="(item, index) in groupList" :key="index + 1" :value="index + 1">{{ index+ 1 }}</a-select-option>
                        </a-select>
                    </template>
                    <template v-slot:level="text, record">
                        <a-select v-model="record.levelNum" placeholder="请选择层数" @change="e => changImageInfo(e, record, 'groupId')">
                            <a-select-option v-for="(item, index) in levelList" :key="index + 1" :value="index + 1">{{ index+ 1 }}</a-select-option>
                        </a-select>
                    </template>
                </a-table> -->
                </div>
            </div>
        </div>
        <!-- 查看原图 -->
        <a-drawer :visible="originModalVisible" :width="450" :destroyOnClose="true" @cancel="handleOriginCancel" title="查看原图" @close="handleOriginCancel">
            <!--<template slot="footer">
                <a-button key="back" @click="handleOriginCancel">
                    取消
                </a-button>
            </template>-->
            <a-spin :spinning="originLoading" class="view-origin-image">
                <div style="text-align: center; margin-bottom: 15px;">{{ originData.imageName }}</div>
                <!--<img style="width: 100%" :src="originData.src"/>-->
                <TransformImage :imgUrl="originData.src"/>
            </a-spin>
            <div
                :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
                }"
            >
                <a-button :style="{ marginRight: '8px' }" @click="handleOriginCancel">
                取消
                </a-button>
            </div>
        </a-drawer>

        <!-- 画图 -->
        <a-modal :visible="drawerVisible" :width="1200" :destroyOnClose="true" class="drawer-modal" @cancel="handleDrawCancel" title="图像标注">
            <template slot="footer">
                <a-button type="primary" @click="saveImage">
                    保存
                </a-button>
                <a-button @click="handleDrawCancel">
                    取消
                </a-button>
            </template>
            <a-spin :spinning="drawerLoading">
                <DrawImage @setPos="setPosition" :src="currentImage.imageUrls"  ref="drawImage"/>
            </a-spin>
        </a-modal>
    </a-spin>
</template>

<script>
    import { message } from 'ant-design-vue';
    import DrawImage from './components/DrawImage/index';
    import TransformImage from './components/TransformImage';
    import { optionFolderNameList,optionSampleNumberList } from "@/api/image/database";
    import { optionModelNameList, optionModelVersionList, optionResultInforList,
    getImageResult, getImageSearchHistory, optionResultInforById, saveData, deleteData, getOriginImage, saveImage, reImageRecognition  } from "@/api/image/routine";
    import ImageList from './ImageListNotReport';
    import HandleImage from './components/HandleImage';
    import { baseUrl } from '../utils/request';
    // import CatalystChart from "./CatalystChart";

    export default {
        components: {
            ImageList,
            HandleImage,
            DrawImage,
            TransformImage
        },
        data() {
            return {
                loading: false,
                // 查询参数
                queryParams: {
                },
                folderList: [],
                sampleNumberList:[],
                modelNameList: [],
                modelVersionList: [],
                resultInfoList: [],
                imageFiles: [],
                currentImage: {}, // 当前选中的图片信息
                currentImageImageInfo: {}, // 选中的图片的右侧详细信息
                imageInfoList: [], // 右侧图片列表数据
                groupList: Array(300), // 组别
                levelList: Array(20), // 层数
                columns: [],
                originLoading: false,
                originModalVisible: false,
                originData: {},
                drawerVisible: false,
                drawerLoading: false,
                positionList: [],
                // img: new Image(),
                // context:{},
            }
        },
        mounted() {
            // this.initDraw();
            this.optionFolderNameList();
            this.optionSampleNumberList();
            this.optionModelNameList();
            this.getSearchHistory();
        },

        methods: {
            // 获取文件夹名称
            optionFolderNameList() {
                optionFolderNameList().then(res => {
                    if(res.success && res.data) {
                        this.folderList = res.data || [];
                    }
                });
            },
            // 获取样品编号
          optionSampleNumberList() {
            optionSampleNumberList().then(res => {
                if(res.success && res.data) {
                  this.sampleNumberList = res.data || [];
                }
              });
            },
            // 获取模型数据
            optionModelNameList() {
                optionModelNameList().then(res => {
                    if(res.success && res.data) {
                        this.modelNameList = res.data || [];
                    }
                });
            },
            // 根据模型获取版本
            changeModelName(e) {
                this.queryParams = {
                    ...this.queryParams,
                    modelVersion: undefined,
                    expertmentalInformation: undefined,
                };
                this.optionModelVersionList();
                this.optionResultInforList();
            },
            // 获取模型版本名称
            optionModelVersionList() {
                optionModelVersionList({
                  modelName: this.queryParams.modelName
                }).then(res => {
                    if(res.success && res.data) {
                        this.modelVersionList = res.data || [];
                    }
                });
            },
            // 获取实验信息
            optionResultInforList() {
                this.queryParams = {
                    ...this.queryParams,
                    expertmentalInformation: undefined,
                };
                const { modelName, modelVersion } = this.queryParams;
                optionResultInforList({
                   modelName,
                   modelVersion,
                }).then(res => {
                    if(res.success && res.data) {
                        this.resultInfoList = res.data || [];
                    }
                });
            },
            
            // 获取历史搜索记录
            getSearchHistory() {
                getImageSearchHistory().then(res => {
                    if(res.success && res.data) {
                        const sampleNumber = res.data.sampleNumber;
                        const sampleNumberArr = sampleNumber ? JSON.parse(sampleNumber) : [];
                        this.queryParams = { ...res.data };
                        if(sampleNumberArr.length > 0) {
                            this.queryParams.sampleNumber = sampleNumberArr[0];
                        }
                    }
                    this.search({init: true});
                });
            },

            // 搜索数据
            search(params) {
                const { createTime, sampleNumber, modelName, modelVersion, expertmentalInformation } = this.queryParams;
                this.loading = true;
                getImageResult({
                    createTime,
                    /*folderName,*/
                  sampleNumber,
                    modelName,
                    modelVersion,
                    expertmentalInformation,
                }).then(res => {
                    this.loading = false;
                    if(res.success && res.data) {
                        this.imageFiles = (res.data.data || []).map(ele => {
                            return {
                                ...ele,
                                url: 'data:image/jpeg;base64,' + ele?.imageCode,
                                name: ele.imageName
                            };
                        });
                        if(params?.init && !this.currentImage.id) {
                            this.currentImage = this.imageFiles?.[0] || {};
                        }
                        if(this.currentImage.id) {
                            this.currentImage = this.imageFiles.find(ele => ele.id === this.currentImage.id);
                            console.log('wwwwwwwwwwwww:', this.currentImage);
                            this.imageClick(this.currentImage);
                        }
                    }
                });
            },
            querySearch() {
                this.imageFiles = [];
                this.currentImage = {};
                this.currentImageImageInfo = {};
                this.imageInfoList = [];
                this.columns = [];
                this.search();
            },
            // 重置
            resetQuery() {
                this.$refs["queryForm"].resetFields();
                this.querySearch();
            },
            // 按钮重置
            reload() {
                this.$refs["queryForm"].resetFields();
                this.imageFiles = [];
                this.currentImage = {};
                this.currentImageImageInfo = {};
                this.imageInfoList = [];
                this.columns = [];
            },
            changImageInfo(e, record, name) {
                this.loading = true;
                saveData({
                   id: record.id,
                    groupId: record.groupId,
                    levelNum: record.levelNum,
                }).then(res => {
                    this.loading = false;
                    if(res.success) {
                        record[name] = e;
                        message.success('操作成功');
                    }
                });
            },
            imageClick(item) {
                if(!item.id) {
                    return;
                }
                this.loading = true;
                this.currentImageImageInfo = {};
                this.columns = [];
                this.imageInfoList = [];
                this.currentImage = { ...item, imageUrls: 'data:image/jpeg;base64,' + item?.imageCode };
                optionResultInforById(item.id).then(res => {
                    this.loading = false;
                    if(res.success) {
                        this.currentImageImageInfo = res.data;
                        const sampleType = this.currentImageImageInfo.sampleType;
                        if(sampleType === '条纹相') {
                            this.columns = [
                                {
                                    title: '序号Label',
                                    dataIndex: 'labelName',
                                },
                                {
                                    title: '组别Group',
                                    dataIndex: 'groupId',
                                    scopedSlots: {customRender: 'group'}
                                },
                                {
                                    title: '层数',
                                    dataIndex: 'levelNum',
                                    scopedSlots: {customRender: 'level'}
                                },
                                {
                                    title: '长度',
                                    dataIndex: 'length',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'action',
                                }
                            ];
                        }

                        if(sampleType === '三氧化二铝棒状' || sampleType === '三氧化二铝孔隙率') {
                            this.columns = [
                                {
                                    title: '序号Label',
                                    dataIndex: 'labelName',
                                },
                                {
                                    title: '长度',
                                    dataIndex: 'length',
                                },
                                {
                                    title: '孔隙率',
                                    dataIndex: 'composite',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'action',
                                }
                            ];
                        }
                        const resultMap = res.data?.groupIdAndResulLengthListMap || {};
                        const newList = [];
                        for(let key in resultMap) {
                            newList.push({
                                name: key,
                                list: resultMap[key]
                            });
                        }
                        this.imageInfoList = newList;
                    }
                });
            },

            // 导出
            exportImage() {
                // id:识别结果ID
                // imageId:识别结果图片ID
                // modelName:模型名称
                // modelVersion:模型版本信息
                // expertmentalInformation:实验信息
                let html = '';
                if(this.currentImage.id) {
                    html += 'id=' + this.currentImage.id;
                }
                if(this.currentImageImageInfo.id) {
                    html += '&imageId='+this.currentImageImageInfo.id;
                }
                const { modelName, modelVersion, expertmentalInformation} = this.queryParams;
                if(modelName !== undefined) {
                    html += '&modelName='+modelName;
                }
                if(modelVersion !== undefined) {
                    html += '&modelVersion='+modelVersion;
                }
                if(expertmentalInformation !== undefined) {
                    html += '&expertmentalInformation='+expertmentalInformation;
                }

                window.open(baseUrl + '/api/ele/image/database/resultlength/excel/export?'+html);
            },
            // 删除
            deleteById(item) {
                deleteData(item.id).then(res => {
                    if(res.success) {
                        message.success('操作成功');
                        this.imageClick(this.currentImage);
                    }
                });
            },
            originModalOpen() {
                if(!this.currentImageImageInfo?.id) {
                    return;
                }
                this.originModalVisible = true;
                this.originLoading = true;
                getOriginImage(this.currentImageImageInfo?.id).then(res => {
                    this.originLoading = false;
                    if(res.success) {
                        this.originData = {
                            ...res.data,
                            src: 'data:image/jpeg;base64,' + res.data?.orgImageCode
                        };
                    }
                });
            },
            handleDrawOpen() {
                this.drawerVisible = true;
            },
            handleOriginCancel() {
                this.originModalVisible = false;
            },
            handleDrawCancel() {
                this.drawerVisible = false;
            },
            setPosition(pos) {
                this.positionList = pos || [];
            },
            saveImage() {
                const positionList = this.$refs.drawImage.positionList || [];
                const imagesLabelList = positionList.map(ele => {
                    const { x, y, h, w} = ele;
                    return {
                        startCoordinateX: x,
                        startCoordinateY: y,
                        endCoordinateX: x + w,
                        endCoordinateY: y+h
                    };
                });
                this.drawerLoading = true;
                saveImage({
                    id: this.currentImage.id,
                    imagesLabelList: imagesLabelList
                }).then(res => {
                    this.drawerLoading = false;
                    if(res.success) {
                        message.success('操作成功');
                        this.handleDrawCancel();
                    }
                });
            },
            imageRegAgain() {
                this.loading = true;
                reImageRecognition({id: this.currentImage.id}).then(res => {
                    this.loading = false;
                    if(res.success) {
                        this.search();
                    }
                });
            }
            // initDraw() {
            //     this.img.src = imgurl;
            //     const canvas = document.querySelector('#canvas');
            //     this.context = canvas.getContext('2d');

            //     const w = document.getElementById('canvasDiv').clientWidth;
            //     const h = document.getElementById('canvasDiv').height;

            //     // let w = this.img.height;
            //     // let h = this.img.width;
            //     console.log(w, h);
            //     canvas.width = w;
            //     canvas.heigth = h;
            //     setTimeout(() => {
            //         console.log(this.img.width * 600 / this.img.height);
            //         this.context.drawImage(this.img, 0, 0, w, h); //在画布上定位图像


            //     }, 10)
            // },
            // canvasDown(e) {
            //     this.context.strokeStyle = "red";
            //     this.context.lineWidth = 2;
            //     let arr = [10,20,30,40,50];
            //     for (let i=0; i<arr.length; i++){
            //         console.log(i,arr[i]);
            //         this.context.moveTo(arr[i],arr[i+10]);
            //         this.context.lineTo(arr[i],arr[i+20]);
            //         this.context.stroke();
            //     }
            //     this.context.stroke();

            //     this.context.strokeRect(10, 20, 30, 40);


            //     console.log("按下");
            //     this.context.font = "20px '微软雅黑'";
            //     this.context.fillStyle = "red";
            //     this.context.fillText("point", e.offsetX, e.offsetY)



            // },
            // canvasMove(e) {
            //     console.log("移动")
            // },
            // canvasUp(e) {
            //     console.log("抬起")
            // },
            // resetCanvas() { // 清空画布
            //     this.context.fillStyle = '#fff';
            //     this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
            //     this.context.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
            //     this.context.fillStyle = '#000'
            // },
        },


    }

</script>

<style lang="less">
    .opera-home .main_hidden {
        border: 1px solid #cccccc;
        margin-bottom: 10px;
        // padding: 0px 22px;
        justify-content: space-between;
        box-shadow: 0px 1px 12px 0px rgba(61,74,148,0.5);
        border-radius: 4px;
        margin: 6px;
    }
    #canvas {
        border: 1px solid;
    }

    .opera-main {
        // background:lightskyblue;
        display: flex;
        box-shadow: 0px 1px 12px 0px rgb(61 74 148 / 50%);
    border-radius: 4px;
    margin: 6px;
    }

    .opera-img {
        flex: 3;
        display: flex;
        height: 70vh;
        overflow: hidden;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
    }

    .opera-img-action {
        width: 28px;
        background: lightskyblue;
    }
    .opera-img-canvas {
        flex: 1;
        width: 100%;
        border: 1px solid #666666;
    }

    .opera-info {
        flex: 2;
        background: #ffffff;
        border: 1px solid #cccccc;
        // background:  #4F60BA;
        overflow-y: auto;
        height: 70vh;
        margin-left: 10px;
        box-shadow: 0px 1px 12px 0px rgba(61,74,148,0.5);
        border-radius: 4px;
    }

    .opera-info .ant-table-content {
        //  height: 60vh;
         overflow: auto;
    }

    .opera-info {
        .ant-table-placeholder, .ant-table-wrapper,
        .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-content {
            height: 100%;
        }

        .ant-table-placeholder {
            border: none;
        }
    }

    .opera-img-list  .image_div {
       width: 150px;
        height: 150px;
    }
    .opera-table-tltle {
        height: 28px;
        background: #E6E6E6;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .otd-title {
        text-align: center;
    }
    .otd-list {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .otd-tab, .ott-tab {
        flex: 1;
        text-align: center;
        padding: 0px 5px;

        .ant-select {
            width: 100%;
        }
    }
    .view-origin-image {
        .pop-up-main {
            height: 60vh;
        }
    }
    .drawer-modal {
        .ant-modal {
            top: 20px;

            .ant-modal-body {
                max-height: 85vh;
            }
        }
    }
    a{
        color: #4F60BA;
    }
</style>
