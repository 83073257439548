import request from '@/utils/request'

// 催化剂类型列表下拉框数据
export function optionCatalystTypeList(query) {
    return request({
        url: '/api/ele/image/database/optionCatalystTypeList',
        method: 'get',
        params: query
    })
}

// 列表文件名称下拉框数据
export function optionFolderNameList(query) {
    return request({
        url: '/api/ele/image/database/optionFolderNameList',
        method: 'get',
        params: query
    })
}

// 列表样品编号下拉框数据
export function optionSampleNumberList(query) {
  return request({
    url: '/api/ele/image/database/optionSampleNumberList',
    method: 'get',
    params: query
  })
}

// 列表请求
export function queryList(query) {
    return request({
        url: '/api/ele/image/database/list',
        method: 'get',
        params: query
    })
}

// 查看请求
export function queryDataById(id) {
    return request({
        url: '/api/ele/image/database/query/' + id,
        method: 'get',
    })
}

// 新增请求
export function addData(data) {
    return request({
      url: '/api/ele/image/database/add',
      method: 'post',
      data: data
    })
}

// 修改请求
export function updateData(data) {
    return request({
      url: '/api/ele/image/database/update',
      method: 'post',
      data: data
    })
}

// 删除图片
export function deleteImageById(id) {
    return request({
      url: '/api/ele/image/database/delete/image/' + id,
      method: 'delete'
    })
}

// 删除图像数据库
export function deleteDatabaseById(id) {
    return request({
      url: '/api/ele/image/database/' + id,
      method: 'delete'
    })
}

// 导出
export function exportById(id) {
    return request({
        url: '/api/ele/image/database/export/' + id,
        method: 'get',
    })
}

// 获取所有图像名称
export function optionImageNameList() {
    return request({
        url: '/api/ele/knowledge/database/optionImageNameList',
        method: 'get',
    })
}
