<template >
    <div class="draw-image">
        <div class="d-content">
            <img :src="src" :width="width" :height="height"/>
            <canvas ref="markCanvas" tabindex='0'></canvas>
        </div>
        <div class="d-handle">
            <div>
                <a-button type="primary" @click="startStopDraw">{{ isDrawing ? '停止标注' : '开始标注'}}</a-button>
            </div>
            <div style="margin-top: 10px;">
                <a-button type="primary" @click="clearCanvas">清除画布</a-button>
            </div>
        </div>
    </div>
</template>

<script>
import { draw, setThis } from "./draw"; 
export default {
    name: "DrawImage.vue",
    props: {
        src: {
            type: String,
            default: '',
        },
        width: 
        {
            type: String,
            default: '1000',
        },
        height: 
        {
            type: String,
            default: '1000',
        },
    },
    data(){
        return{
            positionList: [],
            isDrawing: false,
            cav: null,
            ctx: null,

        }
    },
    created() {
        setTimeout(() => {
            this.initCanvas();
        }, 200);
    },
    watch:{},
    methods: {
        /* 画布初始化 */
        initCanvas() {
            let that = this
            this.$nextTick(() => {
                // 初始化canvas宽高
                let cav = this.$refs.markCanvas;
                this.cav = cav;
                cav.width = this.width;
                cav.height = this.height;
                let ctx = cav.getContext('2d');
                this.ctx = ctx;
                ctx.strokeStyle = 'red'
                cav.style.cursor = 'crosshair'
                
                // 计算使用变量
                let list = this.positionList; // 画框数据集合, 用于服务端返回的数据显示和绘制的矩形保存
                // 若服务端保存的为百分比则此处需计算实际座标, 直接使用实际座标可省略
                list.forEach(function (value, index, array) {
                    let newValue = {
                        x: value.x * cav.width,
                        y: value.y * cav.height,
                        w: value.w * cav.width,
                        h: value.h * cav.height,
                    }
                    list.splice(index, 1, newValue)
                })

                // 若list长度不为0, 则显示已标记框
                if (list.length !== 0) {
                    list.forEach(function (value, index, array) {
                        // 遍历绘制所有标记框
                        ctx.rect(value.x, value.y, value.w, value.h);
                        ctx.stroke();
                    });
                }
                // data数据传递
                setThis(that);
                // 调用封装的绘制方法
                draw(cav,ctx,list);
 
                // 备注: js中对象操作指向的是对象的物理地址, 获取绘制完矩形的结果数组直接取用或处理this.positionList即可
            })
        },
        // 开始/停止画图
        startStopDraw() {
            this.isDrawing = !this.isDrawing;
            if(this.cav && this.ctx) {
                draw(this.cav,this.ctx,this.positionList);
            }
        },
        // 清除画布
        clearCanvas() {
            if(this.cav && this.ctx) {
                this.ctx.clearRect(0, 0, this.cav.width, this.cav.height);
                this.positionList = [];
                draw(this.cav,this.ctx,[]);
            }
        }
    }
}

</script>
<style lang="less">
.draw-image {
    display: flex;
    position: relative;
}
.d-content {
    position: relative;
    // top: 50%;
    // left: 50%;
    // transform: translateX(-50%) translateX(-50%);
    width: 1000px;
    height: 1000px;
    
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }
 
    canvas {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
}
.d-handle {
    padding: 0px 18px;
}
</style>

