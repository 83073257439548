import request from '@/utils/request'

// 模型名称下拉框加载
export function optionModelNameList(query) {
  return request({
    url: '/api/ele/image/database/optionModelNameList',
    method: 'get',
    params: query
  })
}

// 模型版本加载
export function optionModelVersionList(query) {
  return request({
    url: '/api/ele/image/database/optionModelVersionList',
    method: 'get',
    params: query
  })
}

// 实验信息加载
export function optionResultInforList(query) {
  return request({
    url: '/api/ele/image/database/optionResultInforList',
    method: 'get',
    params: query
  })
}

// 识别后的图片加载
export function getImageResult(query) {
  return request({
    url: '/api/ele/image/database/imageRecognition/imageResult',
    method: 'get',
    params: query
  })
}

// 识别后的图片加载--最新历史扫描记录
export function getImageSearchHistory(query) {
  return request({
    url: '/api/ele/image/database/imageRecognition/imageResult/latestRecord',
    method: 'get',
    params: query
  })
}

// 识别图片详细信息
export function optionResultInforById(id) {
  return request({
    url: '/api/ele/image/database/imageRecognition/result/' + id,
    method: 'get',
  })
}

// 保存分析结果
export function saveData(data) {
  return request({
    url: '/api/ele/image/database/imageRecognition/result/save',
    method: 'post',
    data: data
  })
}

// 导出分析结果
export function exportResult(query) {
  return request({
    url: '/api/ele/image/database/resultlength/excel/export',
    method: 'get',
    params: query
  })
}

// 删除
export function deleteData(id) {
  return request({
    url: '/api/ele/image/database/imageRecognition/result/delete/' + id,
    method: 'delete'
  })
}

// 查看原图
export function getOriginImage(id) {
  return request({
    url: '/api/ele/image/database/query/image/' + id,
    method: 'get',
  })
}

// 图像标注保存
export function saveImage(data) {
  return request({
    url: '/api/ele/image/database/reImageRecognition/save',
    method: 'post',
    data: data
  })
}

// 图像标注后重新识别
export function reImageRecognition(query) {
  return request({
    url: '/api/ele/image/database/reImageRecognition',
    method: 'get',
    params: query
  })
}

// 刷新重新识别结果
export function refurbish(query) {
  return request({
    url: '/api/ele/image/database/reImageRecognition/refurbish',
    method: 'get',
    params: query
  })
}

// 导出分析结果
export function downloadResult(query) {
  return request({
    url: '/api/ele/image/database/resultlength/excel/export',
    method: 'get',
    params: query
  })
}
